/// <reference path="../suxesiv.ts" />
/// <reference path="../../../typings/index.d.ts" />


namespace Suxesiv {
    enum SliderOptionType {
        Int,
        Bool,
        String,
        Any
    }

    export class Slider {

        // responsive breakpoints
        public readonly BREAKPOINTS: { [name: string]: number } = {
            'xs': 0,
            'sm': 576,
            'md': 769,
            'lg': 1024,
            'xl': 1250
        };

        protected readonly POSSIBLE_OPTIONS: { [name: string]: { type: SliderOptionType, default: any, attrName: string } } = {
            items: {
                type: SliderOptionType.Int,
                default: 1,
                attrName: 'items'
            },
            margin: {
                type: SliderOptionType.Int,
                default: 0,
                attrName: 'margin'
            },
            loop: {
                type: SliderOptionType.Bool,
                default: false,
                attrName: 'loop'
            },
            center: {
                type: SliderOptionType.Bool,
                default: false,
                attrName: 'center'
            },
            mouseDrag: {
                type: SliderOptionType.Bool,
                default: true,
                attrName: 'mouse-drag'
            },
            touchDrag: {
                type: SliderOptionType.Bool,
                default: true,
                attrName: 'touch-drag'
            },
            pullDrag: {
                type: SliderOptionType.Bool,
                default: true,
                attrName: 'pull-drag'
            },
            freeDrag: {
                type: SliderOptionType.Bool,
                default: false,
                attrName: 'free-drag'
            },
            stagePadding: {
                type: SliderOptionType.Int,
                default: 0,
                attrName: 'stage-padding'
            },
            merge: {
                type: SliderOptionType.Bool,
                default: false,
                attrName: 'merge'
            },
            mergeFit: {
                type: SliderOptionType.Bool,
                default: true,
                attrName: 'merge-fit'
            },
            autoWidth: {
                type: SliderOptionType.Bool,
                default: false,
                attrName: 'auto-width'
            },
            /*autoHeight: {
             type: SliderOptionType.Bool,
             default: false,
             attrName: 'auto-height'
             },*/
            startPosition: {
                type: SliderOptionType.Int,
                default: 0,
                attrName: 'start-position'
            },
            URLhashListener: {
                type: SliderOptionType.Bool,
                default: false,
                attrName: 'url-hash-listener'
            },
            nav: {
                type: SliderOptionType.Bool,
                default: false,
                attrName: 'navigation'
            },
            navRewind: {
                type: SliderOptionType.Bool,
                default: true,
                attrName: 'navigation-rewind'
            },
            slideBy: {
                type: SliderOptionType.Int,
                default: 1,
                attrName: 'slide-by'
            },
            dots: {
                type: SliderOptionType.Bool,
                default: true,
                attrName: 'dots'
            },
            dotData: {
                type: SliderOptionType.Bool,
                default: false,
                attrName: 'dot-data'
            },
            lazyLoad: {
                type: SliderOptionType.Bool,
                default: false,
                attrName: 'lazy-load'
            },
            autoplay: {
                type: SliderOptionType.Bool,
                default: false,
                attrName: 'autoplay'
            },
            autoplayTimeout: {
                type: SliderOptionType.Int,
                default: 5000,
                attrName: 'autoplay-timeout'
            },
            autoplayHoverPause: {
                type: SliderOptionType.Bool,
                default: false,
                attrName: 'autoplay-hover-pause'
            },
            smartSpeed: {
                type: SliderOptionType.Int,
                default: 250,
                attrName: 'smart-speed'
            },
            fluidSpeed: {
                type: SliderOptionType.Bool,
                default: true,
                attrName: 'fluid-speed'
            },
            autoplaySpeed: {
                type: SliderOptionType.Int,
                default: 500,
                attrName: 'autoplay-speed'
            },
            navSpeed: {
                type: SliderOptionType.Int,
                default: 500,
                attrName: 'navigation-speed'
            },
            dotsSpeed: {
                type: SliderOptionType.Int,
                default: 500,
                attrName: 'dots-speed'
            },
            dragEndSpeed: {
                type: SliderOptionType.Int,
                default: 250,
                attrName: 'dots-speed'
            },
            video: {
                type: SliderOptionType.Bool,
                default: false,
                attrName: 'video'
            },
            animateOut: {
                type: SliderOptionType.Bool,
                default: false,
                attrName: 'animate-out'
            },
            animateIn: {
                type: SliderOptionType.Bool,
                default: false,
                attrName: 'animate-in'
            },
            fallbackEasing: {
                type: SliderOptionType.Bool,
                default: false,
                attrName: 'fallback-easing'
            }
        };

        private element: JQuery;

        /**
         * @param {Node} element
         */
        public constructor(element: Node) {
            this.element = $(element);

            let options = this.readOptions();

            // set values
            options['responsiveRefreshRate'] = 100;
            options['responsiveClass'] = true;
            options['navText'] = [this.parseAttr('navigation-prev-text', 'prev'), this.parseAttr('navigation-next-text', 'next')];

            // responsive options
            let responsiveOptions: { [breakpoint: number]: { [key: string]: any } } = {};
            for (let breakpointKey in this.BREAKPOINTS) {
                if (this.BREAKPOINTS.hasOwnProperty(breakpointKey)) {
                    let breakpoint = this.BREAKPOINTS[breakpointKey];

                    let _options = this.readOptions(breakpointKey);

                    if (_options && Object.keys(_options).length > 0) {
                        responsiveOptions[breakpoint] = _options;
                    }
                }
            }


            if (Object.keys(responsiveOptions).length > 0) {
                options.responsive = responsiveOptions;
            }

            this.element.owlCarousel(options);
        }

        /**
         *
         * @param infix
         * @return {{}}
         */
        protected readOptions(infix?: string | null): { [key: string]: any } {
            let options:{[key:string]:any} = {};

            for (let key in this.POSSIBLE_OPTIONS) {
                if (this.POSSIBLE_OPTIONS.hasOwnProperty(key)) {
                    let optionDefinition = this.POSSIBLE_OPTIONS[key];
                    let optionName = optionDefinition.attrName;

                    if (infix) {
                        optionName = infix + '-' + optionName;
                    }

                    if (this.hasAttribute(optionName)) {
                        let value = null;
                        switch (optionDefinition.type) {
                            case SliderOptionType.Bool:
                                value = this.parseAttrBoolean(optionName, optionDefinition.default);
                                break;

                            case SliderOptionType.Int:
                                value = this.parseAttrInt(optionName, optionDefinition.default);
                                break;

                            case SliderOptionType.String:
                            case SliderOptionType.Any:
                            default:
                                value = this.parseAttr(optionName, optionDefinition.default);
                                break;
                        }

                        if (value !== null) {
                            options[key] = value;
                        }
                    }
                }
            }

            return options;
        }

        /**
         *
         * @param attr
         * @return {boolean}
         */
        protected hasAttribute(attr: string): boolean {
            return !!this.element.data(attr);
        }

        /**
         * @param {string} attr
         * @param {any} defaultValue
         * @returns {any}
         */
        protected parseAttr(attr: string, defaultValue?: any): any | null {
            let value = this.element.data(attr);

            if (typeof value !== "undefined") {
                return value;
            }

            return defaultValue;
        }

        /**
         * @param {string} attr
         * @param {number} defaultValue
         * @returns {number}
         */
        protected parseAttrInt(attr: string, defaultValue: number = 0): number {
            let value = this.parseAttr(attr, defaultValue);

            return parseInt(value);
        }


        /**
         * @param {string} attr
         * @param {boolean} defaultValue
         * @returns {boolean}
         */
        protected parseAttrBoolean(attr: string, defaultValue: boolean = false): boolean {
            let value = this.parseAttr(attr, defaultValue);

            if (typeof(value) === "boolean") {
                return value;
            }

            switch (value.toLowerCase().trim()) {
                case "true":
                case "yes":
                case "1":
                    return true;

                case "false":
                case "no":
                case "0":
                case null:
                    return false;
            }

            return defaultValue;
        }
    }
}

